body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  margin: 0;
  height: 100%;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
header a {
    color: inherit;
    text-decoration: none;
}

p a {
  color: inherit;
}

@keyframes headShake {
  0 {
    transform: translateX(0)
  }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg)
  }
  16.5% {
    transform: translateX(5px) rotateY(7deg)
  }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg)
  }
  43.5% {
    transform: translateX(2px) rotateY(3deg)
  }
  50% {
    transform: translateX(0)
  }
}

.headShake {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-name: headShake;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s linear;
}
